<template>
    <div>

    </div>

</template>

<script>
export default {
    name: "ItemEmptyNoneLayout",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        finishGetList: {default: true},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
